import Popup from './popup_fn';

export default function initPoupCityScript() {

    window.popupCity = new Popup('#popup-city', '.btn-popup-location');
    window.popupCityOrder = false;
    const popupCityClose = document.querySelector('.popup-city__btn-close');
    const popupCities = document.querySelectorAll('#popup-city li a');

    if(!popupCity.popup) return;
    
    if(popupCity.btnsShow.length) {
        popupCity.btnsShow.forEach(btn => {
            btn.addEventListener('click', e => {
                e.preventDefault();
    
                if(window.popupCityCheck && window.popupCityCheck.popup) {
                    window.popupCityCheck.close();
                }

                if(btn.hasAttribute('data-location-order')) {
                    window.popupCityOrder = true;
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                }

                popupCity.btnsShow.forEach(btnCity => btnCity.classList.add('active'));
                popupCity.open();
            })
        })
    }

    if(popupCityClose) {
        popupCityClose.addEventListener('click', e => {
            e.preventDefault();
            popupCity.btnsShow.forEach(btnCity => btnCity.classList.remove('active'));
            popupCity.close();
            window.popupCityOrder = false;

            if(!parseInt(getCookie('city')) && document.querySelectorAll('.popup-check__city-inner').length === 1) {
                popupCity.popup.parentElement.classList.add('custom-hover');
            }
        })
    }

    if(popupCities.length) {
        popupCities.forEach(city => {
            city.addEventListener('click', e => {
                e.preventDefault();

                if(city.classList.contains('active')) return;

                let ajax = $.ajax({
                    type: "POST",
                    data:  `id=${city.dataset.id}`,
                    url: "/local/ajax/popup-city.php",
                    dataType: "json"
                });

                ajax.done(function(res) {
                    console.log(res);

                    if(!res) return;

                    popupCities.forEach(el => el.classList.remove('active'));
                    city.classList.add('active');

                    const btnLocationText = popupCity.btnsShow[0].querySelector('span');

                    if(btnLocationText) {
                        btnLocationText.textContent = res.city
                    }

                    if(res.html) {
                        const phonesBlock = document.querySelector('header .phones-block');

                        if(phonesBlock) {
                            phonesBlock.innerHTML = res.html
                        }
                    }

                    window.setCookie('city', '1', { path: '/', expires: 7776 });
                    popupCity.close();
                    location.reload();
                })
            })
        })
    }

    document.addEventListener('click', e => {
        let btn = e.target.classList.contains('btn-popup-location')
            ? e.target
            : e.target.closest('.btn-popup-location');
        let popup = e.target.classList.contains('popup-city')
            ? e.target
            : e.target.closest('.popup-city');

        if(btn || popup) return;
            
        popupCity.btnsShow.forEach(btnCity => btnCity.classList.remove('active'));
        popupCity.close();
    });
}