import { setTabSlider } from "../../common/functions";

export function productCharacters(response) {
    if(!response || !response.result) return;

    const { html: resultHTML } = response;
    const replaceBlock = document.querySelector('.info-wrap.spoiler__body');
    
    if (replaceBlock !== null) {
        let blockToSave = replaceBlock.querySelector('.features-box');
        let blockToSaveHTML = blockToSave.outerHTML;

        replaceBlock.innerHTML = '';
        replaceBlock.insertAdjacentHTML('afterbegin', resultHTML);
        replaceBlock.insertAdjacentHTML('beforeend', blockToSaveHTML);

        const colorsSlider = replaceBlock.querySelector('.colors-slider-box.swiper');

        if(colorsSlider) {
            let slider = setTabSlider({
                slider: colorsSlider,
                count: 4,
                classParent: '.colors-slider-wrap',
                space: 10
            }).init();

            slider.slides.forEach(slide => {
                let link = slide.querySelector('[href]');

                if(!link) return;

                link.addEventListener('click', e => {
                    e.preventDefault();
                    location.href = link.href;
                })
            })
        }

        setAllSpoilers();
    }

    if(window.isRequiredSroll) {
        const sliderReqired = document.querySelector('#required-slider');

        if(sliderReqired) {
            window.scrollTo({
                top: sliderReqired.getBoundingClientRect().top - 120,
                behavior: "smooth"
            })
        }
    }

    function setAllSpoilers() {
        const spoliers = document.querySelectorAll('.spoiler__item');
    
        if (spoliers.length !== 0) {
            spoliers.forEach(spoiler => {
                setSpoiler(spoiler);
            })
        }
    }

    function setSpoiler(element) {
        const itemSwitch = element.querySelector('.spoiler__switch');
        const itemBody = element.querySelector('.spoiler__body');
        const showText = itemSwitch.getAttribute('data-show-text');
        const closeText = itemSwitch.getAttribute('data-close-text');
        const maxHeight = itemBody.getAttribute('data-max-height');

        itemBody.style.maxHeight = `${Number(maxHeight)}px`;

        if (itemBody.scrollHeight >= Number(maxHeight)) {
            itemSwitch.addEventListener('click', function() {
                if (itemBody.classList.contains('spoiler__body--active')) {
                    itemSwitch.innerText = showText;
                    itemBody.style.maxHeight = `${Number(maxHeight)}px`
                } else {
                    itemSwitch.innerText = closeText;
                    itemBody.style.maxHeight = `100%`
                }
                itemBody.classList.toggle('spoiler__body--active');
            })
        } else {
            itemSwitch.remove();
        }
    }
}

export function productGallery(response) {
    if(!response || !response.result) return;

    const { html: data } = response;

    if(!data || !data.length) return;

    const parentBlock = document.querySelector('.reviews-gallery');

    parentBlock.insertAdjacentHTML('afterbegin', data);

    const videos = parentBlock.querySelectorAll('.reviews-gallery__video');

    videos.forEach(video => {
        video.controls = false;
    })

    const galleryItems = parentBlock.querySelectorAll('.reviews-gallery__item:not(.reviews-gallery__item--ignore)');

    if (galleryItems.length >= 1) {
        const addItemHTML = 
        `<div class="reviews-gallery__item reviews-gallery__item--ignore product__tabs-api" data-tab="tab12">
            <svg class="reviews-gallery__video-icon" width="32px" height="32px" viewBox="0 0 24 24" version="1.1">
                <g id="surface1">
                <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 19.6875 2.585938 C 19.570312 2.648438 19.429688 2.765625 19.375 2.855469 C 19.273438 3.003906 19.265625 3.066406 19.265625 3.753906 L 19.265625 4.496094 L 18.496094 4.507812 C 17.652344 4.523438 17.597656 4.542969 17.371094 4.847656 C 17.234375 5.03125 17.234375 5.46875 17.371094 5.652344 C 17.597656 5.957031 17.652344 5.976562 18.496094 5.992188 L 19.265625 6.003906 L 19.265625 6.746094 C 19.265625 7.433594 19.273438 7.496094 19.375 7.644531 C 19.523438 7.875 19.867188 8.035156 20.113281 7.992188 C 20.363281 7.949219 20.652344 7.691406 20.71875 7.460938 C 20.742188 7.363281 20.765625 7 20.765625 6.648438 L 20.765625 6.003906 L 21.511719 5.992188 C 22.339844 5.976562 22.40625 5.953125 22.632812 5.625 C 22.84375 5.316406 22.738281 4.878906 22.390625 4.621094 C 22.269531 4.53125 22.179688 4.523438 21.511719 4.507812 L 20.765625 4.496094 L 20.765625 3.851562 C 20.765625 3.5 20.742188 3.136719 20.71875 3.046875 C 20.640625 2.757812 20.273438 2.484375 19.96875 2.484375 C 19.925781 2.484375 19.800781 2.53125 19.6875 2.585938 Z M 19.6875 2.585938 "/>
                <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 8.667969 3.066406 C 8.550781 3.09375 8.359375 3.179688 8.246094 3.242188 C 7.855469 3.472656 7.671875 3.730469 7.105469 4.867188 C 6.8125 5.457031 6.535156 5.953125 6.492188 5.972656 C 6.449219 5.984375 5.816406 6 5.089844 6 C 4.339844 6 3.648438 6.023438 3.488281 6.050781 C 2.488281 6.234375 1.59375 7.078125 1.335938 8.085938 C 1.273438 8.324219 1.265625 9.078125 1.265625 13.75 C 1.265625 19.734375 1.246094 19.398438 1.585938 20.035156 C 1.914062 20.664062 2.460938 21.125 3.164062 21.367188 L 3.539062 21.492188 L 11.953125 21.492188 C 18.867188 21.492188 20.414062 21.484375 20.625 21.425781 C 21.609375 21.1875 22.433594 20.363281 22.664062 19.402344 C 22.726562 19.144531 22.734375 18.445312 22.734375 14.585938 C 22.734375 10.515625 22.726562 10.054688 22.660156 9.910156 C 22.546875 9.675781 22.339844 9.546875 22.035156 9.523438 C 21.726562 9.507812 21.515625 9.617188 21.355469 9.890625 C 21.257812 10.050781 21.257812 10.121094 21.234375 14.605469 L 21.210938 19.15625 L 21.070312 19.386719 C 20.898438 19.664062 20.570312 19.894531 20.25 19.964844 C 19.914062 20.035156 4.085938 20.035156 3.75 19.964844 C 3.429688 19.894531 3.101562 19.664062 2.929688 19.386719 L 2.789062 19.15625 L 2.773438 13.820312 L 2.765625 8.480469 L 2.863281 8.25 C 2.921875 8.125 3.046875 7.941406 3.148438 7.847656 C 3.507812 7.527344 3.511719 7.527344 5.25 7.5 L 6.820312 7.476562 L 7.128906 7.328125 C 7.609375 7.09375 7.757812 6.890625 8.347656 5.710938 C 8.632812 5.132812 8.902344 4.625 8.945312 4.578125 C 9.007812 4.503906 9.195312 4.5 11.585938 4.5 C 14.054688 4.5 14.164062 4.496094 14.339844 4.40625 C 14.441406 4.355469 14.570312 4.238281 14.628906 4.144531 C 14.855469 3.777344 14.71875 3.285156 14.339844 3.09375 C 14.164062 3.003906 14.058594 3 11.523438 3.003906 C 9.585938 3.003906 8.828125 3.023438 8.667969 3.066406 Z M 8.667969 3.066406 "/>
                <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 11.414062 8.535156 C 10.363281 8.671875 9.417969 9.140625 8.644531 9.910156 C 8.054688 10.5 7.6875 11.125 7.421875 11.984375 C 7.308594 12.367188 7.292969 12.46875 7.292969 13.21875 C 7.289062 13.9375 7.304688 14.085938 7.398438 14.4375 C 7.621094 15.25 8 15.914062 8.601562 16.539062 C 9.214844 17.183594 9.945312 17.617188 10.828125 17.851562 C 11.453125 18.015625 12.476562 18.023438 13.09375 17.867188 C 14.007812 17.640625 14.765625 17.203125 15.398438 16.539062 C 16.003906 15.910156 16.386719 15.230469 16.597656 14.4375 C 16.75 13.851562 16.773438 12.917969 16.648438 12.328125 C 16.453125 11.375 16.039062 10.597656 15.375 9.933594 C 14.570312 9.121094 13.660156 8.671875 12.539062 8.535156 C 12.066406 8.480469 11.882812 8.480469 11.414062 8.535156 Z M 12.820312 10.105469 C 13.222656 10.210938 13.726562 10.46875 14.066406 10.753906 C 15.332031 11.796875 15.609375 13.667969 14.699219 15.039062 C 14.109375 15.921875 13.050781 16.5 12 16.5 C 10.644531 16.5 9.367188 15.582031 8.933594 14.296875 C 8.757812 13.777344 8.71875 13.070312 8.832031 12.554688 C 9.125 11.234375 10.1875 10.25 11.554688 10.027344 C 11.859375 9.980469 12.464844 10.015625 12.820312 10.105469 Z M 12.820312 10.105469 "/>
                </g>
            </svg>
        </div>`;

        parentBlock.querySelector('.reviews-gallery__list').insertAdjacentHTML('afterbegin', addItemHTML);

        const addReviewBlock = parentBlock.querySelector('.reviews-gallery__item--ignore');

        addReviewBlock.addEventListener('click', function(e) {
            window.attachProductTabEvent.call(this, e);
        })

        window.setTabsJs('tab12', addReviewBlock);
    }

    const countVisibleItems = parseInt(parentBlock.querySelector('.reviews-gallery__list').getAttribute('data-gallery-visible-items'));

    if (galleryItems.length > countVisibleItems) {
        const leftCount = parseInt(parentBlock.querySelector('.reviews-gallery__list').getAttribute('data-gallery-left')) + 1;
        const lastItem = galleryItems[countVisibleItems - 1];

        lastItem.classList.add('reviews-gallery__item--blur');
        lastItem.insertAdjacentHTML('beforeend', `<span class="reviews-gallery__more">+${leftCount}</span>`)
    }

    $('[data-fancybox="review-gallery"]').fancybox({
        thumbs : {
            autoStart : true,
            axis      : 'x'
        }
    })

}