import { createElement, createRating } from "../../common/functions";
import { eventHoverItem } from "./events";

let debounceHover;

export function senderAddBasket(url, data = false, cb) {
    const obj = {
        type: 'POST',
        url: url,
        dataType: 'html',
    }

    if (data) {
        obj.data = data
    }

    $.ajax(obj).done(cb)
}

export function setYmDataLayer(item, event, list = false) {
    if(!item) return;

    const objLayer = {
        ecommerce: {
            currencyCode: "RUB",    
        }
    }

    switch (event) {
        case 'click':
            objLayer.ecommerce[event] = {};
            objLayer.ecommerce[event].products = [];
            setItemParams(objLayer.ecommerce[event].products)
            break;
    
        default:
            objLayer.ecommerce[event] = [];
            setItemParams(objLayer.ecommerce[event])
            break;
    }

    window.dataLayerYam.push(objLayer);

    function setItemParams(products) {
        if(item.length) {
            item.forEach((item, index) => {
                let el = item;
                let position = index + 1;
    
                if(
                    typeof item === 'object' &&
                    item.hasOwnProperty('position')
                ) {
                    el = item.el;
                    position = item.position;
                }
    
                products.push(createItemObj(el, position))
            })
        } else {
            products.push(createItemObj(item)) 
        }
    }

    function createItemObj(el, index = false) {
        const obj = {
            id: el.dataset.elementid,
        }
        const objElems = {
            name: el.querySelector('.cat-name a'),
            price: el.querySelector('.cat-price b'),
            brand: el.querySelector('[data-brand]'),
            category: el.querySelector('[data-section]'),
        }

        if(event === 'add') {
            obj.quantity = 1
        }

        if(el.dataset.parent) {
            obj.list = el.dataset.parent;
        } else if(list) {
            obj.list = list;
        } else {
            obj.list = `undefined title`;
        }

        for (const key in objElems) {
            if(!objElems[key]) continue;

            switch (key) {
                case 'name':
                    obj[key] = objElems[key].getAttribute('title')
                    break;

                case 'price':
                    obj[key] = getPrice(objElems[key].textContent)
                    break;

                case 'brand':
                    if(!objElems[key].dataset.brand.length) {
                        break;
                    }

                    obj[key] = objElems[key].dataset.brand
                    break;

                case 'category':
                    if(!objElems[key].dataset.section.length) {
                        break;
                    }

                    obj[key] = objElems[key].dataset.section
                    break;
            }
        }

        if(index) {
            obj.position = index;
        } else {
            const position = el.getAttribute('aria-label');
    
            if(position) {
                obj.position = Number(position.replaceAll(' ', '').split('/')[0]);
            }
        }

        function getPrice(price) {
            if(!price || !price.length) return;
            price = price.replace(/[^.\d]+/g, '').replace(/^([^\.]*\.)|\./g, '$1');
            return price;
        }

        return obj;
    }
}

export function updateItem(item, obj) {
    if (!item || !item.length) return;

    const arr = [
        // desk
        [obj.old_price, '.cat-price-old', 'span:not([class])'],
        [obj.normal_price_currency, '.cat-price-old', '.cat-price-old-cur'],
        [obj.normal_price, '.cat-price', 'b'],
        [obj.normal_price_currency, '.cat-price', 'span'],
        [obj.price_fl, '.cat-price-info', 'b'],
        [obj.price_fl_currency, '.cat-price-info', '.cat-price-info-cur'],
        [obj.custom_quantity_price, '.cat-price-orange', 'b'],
        // line
        [obj.old_price, '.price-old', 'span'],
        [obj.normal_price, '.price-main', 'b'],
        [obj.price_fl, '.price-info', 'b'],
        [obj.discount, '.elm-discount', 'b'],
        [obj.discount_text, '.basket-item__price-text', false],
        // mobile
        [obj.old_price, '.product-sale-price', 'span'],
        [obj.discount, '.product-sale-discount', 'b'],
        [obj.normal_price, '.product-price.normal_price', 'b'],
        [obj.custom_quantity_price, '.product-price.custom_quantity', 'b'],
        [obj.discount_text, '.basket-item__price-text', false],
        [obj.price_fl, '.product-price-other', 'b']
    ]

    arr.forEach(arrItem => changeTextContent(item, ...arrItem));

    item.each(function() {
        updateRating(obj, this);
        updatePhoto(obj, this);
        updateFavorite(obj, this);
        updateCompare(obj, this);
        updateBtnСart(obj, this);

        if(item.length) {
            item[0].dataset.basketQty = obj['basket-quantity'];

            item[0].onmouseenter = function(event) {
                debounceHover = setTimeout(() => eventHoverItem(this), 450);
            };

            item[0].onmouseleave = function(event) {
                clearTimeout(debounceHover)
            };
        }
    })

    item[0].classList.remove('plug');
}

export function updateRating(obj, item) {
    if(!item) return;

    let ratingWrap = item.querySelector('.api-rating-js');

    if(!ratingWrap) return;

    if(!obj.rating['COUNT_ITEMS']) {
        ratingWrap.style.visibility = 'hidden';
        ratingWrap.style.pointerEvents = 'none';
    } else {
        ratingWrap.innerHTML = createRating(obj.rating);
    }

    ratingWrap.classList.remove('api-rating-js');
}

export function updatePhoto(obj, item) {
    if(
        !item || 
        !obj.hasOwnProperty('images') || 
        !obj.images ||
        obj.images.length < 2
    ) return;

    const itemPhotoWrap = item.querySelector('.cat-photo');

    if(!itemPhotoWrap) return;

    const wrapper = createPhotoHoverHtml();
    const hoverItems = wrapper.querySelectorAll('.cat-photo__hover-item');
    const paginationItems = wrapper.querySelectorAll('.cat-photo__hover-pagination span');

    wrapper.addEventListener('mouseout', () => {
        let img = wrapper.closest('.cat-photo').querySelector('img');
        let source = wrapper.closest('.cat-photo').querySelector('source');

        img.src = hoverItems[0].dataset.src;
        
        if(source) {
            source.srcset = hoverItems[0].dataset.src_webp;
        }

        paginationItems.forEach(el => el.classList.remove('active'));
        paginationItems[0].classList.add('active');
    });

    hoverItems.forEach((hoverItem, index) => {
        hoverItem.addEventListener('mouseover', () => {
            let img = hoverItem.closest('.cat-photo').querySelector('img');
            let source = hoverItem.closest('.cat-photo').querySelector('source');

            img.src = hoverItem.dataset.src;

            if(source && hoverItem.dataset.src_webp) {
                source.srcset = hoverItem.dataset.src_webp;
            }

            paginationItems.forEach(el => el.classList.remove('active'));
            paginationItems[index].classList.add('active');
        })
    });

    itemPhotoWrap.insertAdjacentElement('afterbegin', wrapper);

    function createPhotoHoverHtml() {
        const wrapper = createElement('div', ['cat-photo__hover-wrapper']);
        const block = createElement('div', ['cat-photo__hover-block']);
        const pagination = createElement('div', ['cat-photo__hover-pagination']);

        obj.images.forEach((objItem, index) => {
            let item = createElement('div', ['cat-photo__hover-item']);
            let paginationItem = document.createElement('span');

            item.dataset.src = objItem.src;

            if(objItem.src_webp) {
                item.dataset.src_webp = objItem.src_webp;
            }

            if(index === 0) {
                paginationItem.classList.add('active');
            }

            block.append(item);
            pagination.append(paginationItem);
        });

        wrapper.append(block);
        wrapper.append(pagination);

        return wrapper;
    }
}

export function updateBtnСart(obj, item) {
    if(!item) return;

    const btnsCart = item.querySelectorAll('a.btn[data-id]');

    if(!btnsCart.length) return;

    btnsCart.forEach(btn => {
        if(obj['in-basket']) {
            btn.classList.add('btn-second');
            btn.innerHTML = 'ТОВАР В КОРЗИНЕ';
        } else {
            if(obj.available) {
                btn.classList.remove('btn-second');
            } else {
                btn.classList.remove('btn', 'btn-add-cart');
                btn.classList.add('btn-cart', 'btn-white');
                btn.href = item.dataset.detail
                    ? item.dataset.detail
                    : obj.url;
            }

            btn.innerHTML = btn.dataset.buttonName
                ? btn.dataset.buttonName
                : 'КУПИТЬ';
        }

        btn.style.pointerEvents = 'auto';
    })
}

export function updateFavorite(obj, item) {
    if(!item) return;

    const btnFav = item.querySelector('a.btn-cat-favorite[data-id]');

    if (!btnFav) return;

    if(obj['in-favorite']) {
        btnFav.classList.add('active');
    } else {
        btnFav.classList.remove('active');
    }

    btnFav.classList.add('favorite-show');
}

export function updateCompare(obj, item) {
    if(!item) return;

    const btnsCompare = item.querySelector('.btn-cat-compare[data-id]');

    if(!btnsCompare) return;

    if(obj['in-compare']) {
        btnsCompare.classList.add('active');
        btnsCompare.title = 'Убрать из сравнения';
    } else {
        btnsCompare.classList.remove('active');
        btnsCompare.title = 'Добавить в сравнение';
    }
}

export function removeItemBlocks(id, container) {
    if(!container) return;

    const item = container.querySelector(`[data-elementid="${id}"]`);

    if(!item) return;

    const blocks = [
        item.querySelector('.cat-bottom'),
        item.querySelector('.cat-hidden')
    ];

    if(blocks.length) {
        blocks.forEach(block => {
            if(!block) return;
            block.remove();
        })
    }

    item.style.minHeight = 'unset';
}

export function createAvailableContent(data) {
    const { STATUS: status, CLASS: type, ACTUAL_DATE: date, ITEMS: items } = data;
    const container = document.createDocumentFragment();

    const listAvailable = createElement('ul', ['cat-available__list']);
    listAvailable.dataset.typeList = 'available';

    const spanAvailable = createElement('span', [type]);
    spanAvailable.textContent = status;

    const liAvailable = createElement('li', ['cat-available__block']);
    liAvailable.textContent = `Наличие: `;

    liAvailable.append(spanAvailable);
    listAvailable.append(liAvailable);

    if(date && date.length) {
        const liDate = createElement('li');

        liDate.textContent = `Актуально на: ${date}`;
        listAvailable.append(liDate);
    }

    container.append(listAvailable);

    if(items) {
        const list = createElement('ul', ['cat-available__list']);

        list.dataset.typeList = 'store';

        for (const key in items) {
            const { QUANTITY: qty, CLASS: item_type } = items[key]
            const li = document.createElement('li');
            const span = createElement('span', [item_type]);

            span.textContent = qty;

            li.textContent = `${key}: `;
            li.append(span);

            list.append(li);
        }

        container.append(list);
    }

    return container;
}

// fn for update values for reset cash
export function changeTextContent(item, value, parentSelector, contentSelector) {
    if (!item.length) return;

    const wrapper = item.find(parentSelector);

    if (!wrapper.length) return;

    if (value && typeof value === 'object') {
        wrapper.each(function (index, el) {
            switch (el.dataset.name) {
                case 'normal_price':
                    $(el).find('b').html(value.normal_price);
                    break;
                case 'quan_skidka_discount':
                    $(el)
                        .find('.card-stock-title-benefit')
                        .html(value.quan_skidka_discount);
                    break;
                default:
                    $(el).find('b').html(value.price_fl);
            }
        });
    } else {
        if (value) {
            if (wrapper.parent().attr('id') === 'main-price-ajax') {
                // wrapper.text(value);
                wrapper.find('.elm-discount').removeClass('bg-gray');
            } else {
                contentSelector
                    ? wrapper.find(contentSelector).html(value)
                    : wrapper.html(value);
            }
        } else {
            if (wrapper.hasClass('basket-item__price-text')) {
                wrapper.css('visibility', 'hidden');
            }
        }
    }

    wrapper.removeClass('bg-gray');
}