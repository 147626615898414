import { createElement } from "../../common/functions";

export function setCompareIcons(prmData) {
    if(
        !prmData.hasOwnProperty('COMPARE') ||
        !prmData['COMPARE'].length
    ) return;

    const arrCompare = prmData['COMPARE'];
    
    setTimeout(() => {
        const btnsCompare = document.querySelectorAll('.btn-cat-compare[data-id]');

        if(!btnsCompare.length) return;

        btnsCompare.forEach(btn => {
            if(!arrCompare.includes(btn.dataset.id)) return;

            btn.classList.add('active');
            btn.title = 'Убрать из сравнения';

            const iconDetailtText = btn.querySelector('.product__controls-text');

            if(!iconDetailtText) return;

            iconDetailtText.textContent = 'В сравнении'
        })
    })
}

export function showHintCompare(responsive) {
    let hint = document.querySelector('.auth-menu__hint');

    if(hint) {
        const title = document.querySelector('.auth-menu__hint-title');
        const subtitle = document.querySelector('.auth-menu__hint-subtitle');
        const link = document.querySelector('.auth-menu__hint-btn');

        title.textContent = responsive.message;
        subtitle.textContent = `Всего ${responsive.count} товаров`;

        if(!parseInt(responsive.count)) {
            subtitle.classList.add('hidden');
            link.classList.add('hidden');
        } else {
            subtitle.classList.remove('hidden');
            link.classList.remove('hidden');
        }
    } else {
        const authBlock = document.querySelector('.header .auth-menu__block');

        hint = createHintCompare(responsive);
        authBlock.append(hint);
    }

    $(hint).fadeIn();

    clearTimeout(window.debounceCompareHint);
    window.debounceCompareHint = setTimeout(() => $(hint).fadeOut(), 6000);
}

export function recalcParamsHeight() {
    const itemLists = document.querySelectorAll('.cat-params__list');

    if(!itemLists.length) return;

    const arrHeight = [];

    list(getValues);
    list(setValue);

    function list(fn) {
        itemLists.forEach(list => {
            const params = list.querySelectorAll('.cat-params__list-item');

            params.forEach(fn);
        });
    }

    function setValue(param, index) {
        const paramVal = param.querySelectorAll('span')[1];

        paramVal.style.height = arrHeight[index] + 'px';
    }

    function getValues(param, index) {
        const paramVal = param.querySelectorAll('span')[1];

        if(!arrHeight[index]) {
            arrHeight[index] = paramVal.offsetHeight ? paramVal.offsetHeight : 14
        } else {
            arrHeight[index] = arrHeight[index] > paramVal.offsetHeight
                ? arrHeight[index]
                : paramVal.offsetHeight
        }
    }
}

export function initCompareSliders(sliders) {
    const {comapreSlider, comapreSliderTop} = sliders;

    if(!comapreSlider || !comapreSliderTop) return;

    const swiperParams = {
        init: false,
        loop: false,
        preloadImages: false,
        allowTouchMove: false,
        lazy: {
            enabled: true,
            loadPrevNext: true,
        },
        slidesPerView: 4,
        spaceBetween: 35,
    }

    const swiper = new Swiper(comapreSlider, swiperParams);
    const swiperTop = new Swiper(comapreSliderTop, swiperParams);

    const swiperPrev = document.querySelectorAll('.swiper-arrow--prev');
    const swiperNext = document.querySelectorAll('.swiper-arrow--next');

    swiperPrev.forEach(arrow => arrow.addEventListener('click', () => {
        swiper.slidePrev();
        swiperTop.slidePrev();

        if(swiper.isBeginning) {
            swiperPrev.forEach(arrow => arrow.classList.add('swiper-button-disabled'));
        }

        swiperNext.forEach(arrow => arrow.classList.remove('swiper-button-disabled'));
    }));

    swiperNext.forEach(arrow => arrow.addEventListener('click', () => {
        swiper.slideNext();
        swiperTop.slideNext();

        if(swiper.isEnd) {
            swiperNext.forEach(arrow => arrow.classList.add('swiper-button-disabled'));
        }

        swiperPrev.forEach(arrow => arrow.classList.remove('swiper-button-disabled'));
    }))

    if(swiper.slides) {
        swiper.slides.forEach(slide => {
            let linkName = slide.querySelector('.cat-name a');

            if(!linkName) return;

            linkName.addEventListener('click', e => {
                e.preventDefault();
                window.open(linkName.href, e.ctrlKey ? '_blank' : '_self')
            })
        })
    }

    swiper.init();
    swiperTop.init();

    return {
        slider: swiper,
        sliderTop: swiperTop
    }
}

export function reinitCompareItems() {
    window.compareParam = {
        block: {
            top: $('.cat-params').offset().top,
            height: $('.cat-params').height(),
            offset: $('.cat-params').offset().top + $('.cat-params').height()
        }, 
        blockFixed: {
            height: $('.compare-slider__top-wrapper').height()
        }
    }
}

function createHintCompare(responsive) {
    const hint = createElement('div', ['auth-menu__hint']);
    const container = createElement('div', ['auth-menu__hint-container']);
    const title = createElement('span', ['auth-menu__hint-title']);
    const subtitle = createElement('span', ['auth-menu__hint-subtitle']);
    const link = createElement('a', ['auth-menu__hint-btn']);

    title.textContent = responsive.message;
    subtitle.textContent = `Всего ${responsive.count} товаров`;

    link.textContent = 'Показать товары в сравнении';
    link.href = '/personal/compare/';

    if(!parseInt(responsive.count)) {
        subtitle.classList.add('hidden');
        link.classList.add('hidden');
    }

    container.append(title, subtitle, link);
    hint.append(container);

    return hint;
}