export default function initSliderTabs(wrapper, slides, swiper = false) {
    const tabLinks = wrapper.querySelectorAll('.product__tabs-item');
    const tabAll = wrapper.querySelector('.product__tab-all');

    if(tabLinks.length) {
        tabLinks.forEach(tab => {
            tab.addEventListener('click', async function() {
                let groupName = tab.querySelector('.product__tabs-link').innerText;

                refreshTabs();
                refreshWrapper(tab, groupName);
                swiper.update();
                swiper.slideTo(0)
            })
        })
    }

    if(tabAll) {
        tabAll.addEventListener('click', function() {
            slides.forEach(slide => slide.classList.remove('hide'));
            refreshTabs();
            this.classList.add('product__link-wrapper--active');
            swiper.update();
            swiper.slideTo(0)
        })
    }

    async function refreshWrapper(tab, groupName) {
        slides.forEach(slide => {
            slide.dataset.group === groupName
                ? slide.classList.remove('hide')
                : slide.classList.add('hide');
        });

        tab.classList.add('product__tabs-item--active');

        if(tabAll) {
            tabAll.classList.remove('product__tabs-item--active');
        }
    }

    function refreshTabs() {
        tabLinks.forEach(tab => tab.classList.remove('product__tabs-item--active'))

        if(tabAll) {
            tabAll.classList.remove('product__link-wrapper--active');
        }
    }
}