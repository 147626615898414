import changeVersionSite from '../includes/shares/changeVersion';

// popups
import initPoupCity from '../includes/popups/popup_city';
import initPoupCityCheck from '../includes/popups/popop_city_check';
import {
    initPopupLogin,
    initPopupLoginSuccess,
} from '../includes/popups/popup_login';
import initPopupSub from '../includes/popups/popup_subscribe';
import initPopupGuide from '../includes/popups/popup_guide';
import initPopupMain from '../includes/popups/popup_main';
import initPopupPhone from '../includes/popups/popup_phone';
import { setEventsPopupComplect } from '../includes/popups/popup_complect';
import updateProductPrices from '../includes/shares/updateProductPrices';
import { commonAjax, createElement, eventClickAnalogBtn, eventClickResetAuth, flagMaskPhone, getJsBasketAjax, getOrderUncheckedMarkers, maskBel, maskRus, setCourse } from '../common/functions';
import addToCartItem from '../includes/shares/add_to_cart';
import addToFavoriteItem from '../includes/shares/add_to_favorite';
import ajaxSliders from '../includes/shares/ajaxSliders';
import initMoreGoods from '../includes/shares/more_goods';
import adTooltip from '../includes/shares/adTooltip';
import { eventClickCompareBtn } from '../pages/compare/events';
import paFunctions from '../includes/personal_account';
import initEventsCatalogItem from '../includes/catalog.item';
import { productCharacters, productGallery } from '../pages/catalog.element/functions';

// определение пареметров url
$.urlParam = function(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results==null) {
    return null;
    }
    return decodeURI(results[1]) || 0;
} 

// plugin for jqMask
$.fn.setCursorPosition = function(pos) {
    if ($(this).get(0).setSelectionRange) {
      $(this).get(0).setSelectionRange(pos, pos);
    } else if ($(this).get(0).createTextRange) {
      var range = $(this).get(0).createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
};

document.addEventListener('DOMContentLoaded', () => {
    // cart, favorite
    initEventsCatalogItem();
    addToCartItem();
    addToFavoriteItem();

    // phone masked
    const inpPhones = $(`
        input[type="tel"], 
        .tel input, 
        input[name="form_text_20"], 
        input[name="form_text_107"],
        input[name="20"],
        input#PHONE
    `);

    flagMaskPhone(inpPhones);

    // visibility password
    (() => {
        const passwordIcons = document.querySelectorAll('.password-icon');
        const iconHide = `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 512.001 512.001" sxml:space="preserve">
                <path d="M316.332,195.662c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.944,0,15.083    c12.075,12.075,18.752,28.139,18.752,45.248c0,35.285-28.715,64-64,64c-17.109,0-33.173-6.656-45.248-18.752    c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.139-4.16,10.923,0,15.083c16.085,16.128,37.525,25.003,60.331,25.003    c47.061,0,85.333-38.272,85.333-85.333C341.334,233.187,332.46,211.747,316.332,195.662z"/>
                <path d="M270.87,172.131c-4.843-0.853-9.792-1.472-14.869-1.472c-47.061,0-85.333,38.272-85.333,85.333    c0,5.077,0.619,10.027,1.493,14.869c0.917,5.163,5.419,8.811,10.475,8.811c0.619,0,1.237-0.043,1.877-0.171    c5.781-1.024,9.664-6.571,8.64-12.352c-0.661-3.627-1.152-7.317-1.152-11.157c0-35.285,28.715-64,64-64    c3.84,0,7.531,0.491,11.157,1.131c5.675,1.152,11.328-2.859,12.352-8.64C280.534,178.702,276.652,173.155,270.87,172.131z"/>
                <path d="M509.462,249.102c-2.411-2.859-60.117-70.208-139.712-111.445c-5.163-2.709-11.669-0.661-14.379,4.587    c-2.709,5.227-0.661,11.669,4.587,14.379c61.312,31.744,110.293,81.28,127.04,99.371c-25.429,27.541-125.504,128-230.997,128    c-35.797,0-71.872-8.64-107.264-25.707c-5.248-2.581-11.669-0.341-14.229,4.971c-2.581,5.291-0.341,11.669,4.971,14.229    c38.293,18.496,77.504,27.84,116.523,27.84c131.435,0,248.555-136.619,253.483-142.443    C512.854,258.915,512.833,253.091,509.462,249.102z"/>
                <path d="M325.996,118.947c-24.277-8.171-47.829-12.288-69.995-12.288c-131.435,0-248.555,136.619-253.483,142.443    c-3.115,3.669-3.371,9.003-0.597,12.992c1.472,2.112,36.736,52.181,97.856,92.779c1.813,1.216,3.84,1.792,5.888,1.792    c3.435,0,6.827-1.664,8.875-4.8c3.264-4.885,1.92-11.52-2.987-14.763c-44.885-29.845-75.605-65.877-87.104-80.533    c24.555-26.667,125.291-128.576,231.552-128.576c19.861,0,41.131,3.755,63.189,11.157c5.589,2.005,11.648-1.088,13.504-6.699    C334.572,126.862,331.585,120.825,325.996,118.947z"/>
                <path d="M444.865,67.128c-4.16-4.16-10.923-4.16-15.083,0L67.116,429.795c-4.16,4.16-4.16,10.923,0,15.083    c2.091,2.069,4.821,3.115,7.552,3.115c2.731,0,5.461-1.045,7.531-3.115L444.865,82.211    C449.025,78.051,449.025,71.288,444.865,67.128z"/>
            </svg>
        `;
        const iconVisible = `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 511.992 511.992" style="enable-background:new 0 0 511.992 511.992;" xml:space="preserve">
                <path d="M510.096,249.937c-4.032-5.867-100.928-143.275-254.101-143.275C124.56,106.662,7.44,243.281,2.512,249.105    c-3.349,3.968-3.349,9.792,0,13.781C7.44,268.71,124.56,405.329,255.995,405.329S504.549,268.71,509.477,262.886    C512.571,259.217,512.848,253.905,510.096,249.937z M255.995,383.996c-105.365,0-205.547-100.48-230.997-128    c25.408-27.541,125.483-128,230.997-128c123.285,0,210.304,100.331,231.552,127.424    C463.013,282.065,362.256,383.996,255.995,383.996z"/>
                <path d="M255.995,170.662c-47.061,0-85.333,38.272-85.333,85.333s38.272,85.333,85.333,85.333s85.333-38.272,85.333-85.333    S303.056,170.662,255.995,170.662z M255.995,319.996c-35.285,0-64-28.715-64-64s28.715-64,64-64s64,28.715,64,64    S291.28,319.996,255.995,319.996z"/>
            </svg>
        `;

        if (!passwordIcons || !passwordIcons.length) return;

        for (let icon of passwordIcons) {
            icon.addEventListener('click', function () {
                const parentNode = this.parentElement;
                const input = parentNode.querySelector('input');

                this.innerHTML = this.classList.contains('active')
                    ? iconVisible
                    : iconHide;
                input.type = this.classList.contains('active')
                    ? 'password'
                    : 'text';

                this.classList.toggle('active');
            });
        }
    })();

    // ad Tooltips
    (() => {
        const adTooltips = document.querySelectorAll('.ad-tooltip--js');

        if (adTooltips.length !== 0) {
            adTooltips.forEach(tooltipElement => {
                adTooltip(tooltipElement);
            })
        }
    })();

    // copy pay link
    (() => {
        const orderShares = document.querySelectorAll('.order__share-text');

        if(!orderShares.length) return;

        let debounceHintArticle;

        orderShares.forEach(share => {
            share.addEventListener('click', () => {
                if(share.hasAttribute('data-ajax')) {
                    const wrap = share.closest('.order-pay');
                    const inner = share.innerHTML;

                    share.innerHTML = '';
                    share.append(createSpinner());
                    share.style.pointerEvents = 'none';

                    $.ajax({
                        type: 'POST',
                        url: share.getAttribute('data-share-link'),
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            if(!data) return;
            
                            data = JSON.parse(data);
            
                            switch (data.result) {
                                case 'OK':
                                    setClip(share, data.payLink)
                                    share.innerHTML = inner;
                                    share.dataset.shareLink = data.payLink;
                                    share.removeAttribute('data-ajax');
                                    share.style.pointerEvents = 'auto';
                                    break;
                            
                                case 'ERROR':
                                    const error = document.createElement('div');

                                    error.classList.add('pay-error');
                                    error.textContent = 'Ошибка оплаты';

                                    wrap.innerHTML = '';
                                    wrap.append(error);

                                    error.classList.add('active');
                                    break;
                            }
                        }
                    });
                } else {
                    setClip(share, share.getAttribute('data-share-link').trim())
                }
            })
        })

        function setClip(el, link) {
            if(!navigator.clipboard) return;

            clearTimeout(debounceHintArticle);
            navigator.clipboard.writeText(link).then(function() { showMsg(el) });
        }

        function showMsg(el) {
            let msg = el.parentElement.querySelector('.order__share-msg');

            if(!msg) {
                msg = document.createElement('div');

                msg.classList.add('order__share-msg');
                msg.textContent = 'Ссылка скопирована';
                msg.style.display = 'none';

                el.parentElement.append(msg);
            }

            $(msg).fadeIn(200, function() {
                debounceHintArticle = setTimeout(() => $(msg).fadeOut(200), 1000);
            });
        }
    })();

    paFunctions();

    // init popups
    initPoupCity();
    initPoupCityCheck();
    initPopupLogin();
    initPopupLoginSuccess();
    initPopupPhone();
    initPopupSub();
    initPopupMain();
    initPopupGuide();
    setEventsPopupComplect();

    window.updateProductPrices = updateProductPrices;
});

// events
let debounce;

document.addEventListener('click', e => {
    const {target} = e;

    if(
        target instanceof HTMLElement &&
        target.classList.contains('product__button-analog')
    ) {
        e.preventDefault();
        eventClickAnalogBtn(target)
    }

    if(
        target instanceof HTMLElement &&
        target.classList.contains('btn-cat-compare')
    ) {
        e.preventDefault();

        clearTimeout(debounce);
        debounce = setTimeout(() => eventClickCompareBtn(target), 200);
    }

    if(
        target instanceof HTMLElement &&
        (
            target.id === 'show_auth_form' ||
            target.id === 'show_auth_form_sms'
        )
    ) {
        e.preventDefault();

        clearTimeout(debounce);
        debounce = setTimeout(() => eventClickResetAuth(target), 200);
    }

    if(
        target instanceof HTMLElement &&
        target.classList.contains('flag-list__item')
    ) {
        const flagWrap = target.closest('.flag-wrap');
        const flagList = target.closest('.flag-list');
        const inp = flagWrap.querySelector('input');

        console.log('click');

        if(
            !inp.hasAttribute('disabled') ||
            !inp.hasAttribute('readonly')
        ) {
            const alert = flagWrap.querySelector('.phone_warning');

            if(alert) {
                alert.remove();
            }

            if(target.classList.contains('active')) {
                flagList.classList.toggle('active')
            } else {
                const items = flagList.querySelectorAll('.flag-list__item');

                items.forEach(item => item.classList.remove('active'));
                target.classList.add('active');
                inp.value = '';

                switch(inp.dataset.inpType) {
                    case 'phone':
                        if(target.dataset.country === 'rus') {
                            maskRus($(inp))
                        } 

                        if(target.dataset.country === 'bel') {
                            maskBel($(inp))
                        }

                        break;
                    case 'name':
                        const obj = {params: { status: ["ACTIVE"] }};

                        if(target.dataset.country === 'rus') {
                            obj.type = "PARTY"

                            if(inp.dataset.suggestionType) {
                                obj.params.type = inp.dataset.suggestionType
                            }
                        } 

                        if(target.dataset.country === 'bel') {
                            obj.type = "PARTY_BY"

                            if(inp.dataset.suggestionType) {
                                obj.params.filters = [{ type: inp.dataset.suggestionType}]
                            }
                        }
                        
                        $(inp).suggestions().setOptions(obj);
                        break;
                }

                flagList.classList.remove('active')
            }
        }
    }
})

window.addEventListener('load', e => {
    //add simple support for background images:
    const bannerSub = document.querySelector('.main-subscribe-box');

    if(bannerSub) {
        let bannerSubBg;

        if(bannerSub.classList.contains('lazyloaded') && bannerSub.getAttribute('style') === null) {
            bannerSubBg = bannerSub.getAttribute('data-bg');

            if(bannerSubBg) {
                bannerSub.style.backgroundImage = 'url(' + bannerSubBg + ')';
            }
        }

        document.addEventListener('lazybeforeunveil', function(e){
            bannerSubBg = e.target.getAttribute('data-bg');

            if(bannerSubBg) {
                e.target.style.backgroundImage = 'url(' + bannerSubBg + ')';
            }
        });
    }

    // load info
    // getJsBasketAjax();
    ajaxSliders()
    initMoreGoods()
    // getOrderUncheckedMarkers();
    // setCourse();
    changeVersionSite();

    // ajax update get-item-price
    updateProductPrices(
        window.itemPricesId, 
        getCookie('BITRIX_SM_siteType') === 'D' ? 'join_desk' : 'join_mobile'
    );

    // header smallest
    (() => {
        var stickyTop = $('.header').offset().top;

        $(window).scroll(function () {
            // scrollMenu();
            // scrollCatMenu();

            var windowTop = $(window).scrollTop();
            if (stickyTop < windowTop) {
                $('.wrap.wrap-main').addClass('header-smallest-nocatalog');
                $('.wrap.header-catalog')
                    .addClass('header-smallest')
                    .removeClass('header-catalog');
                $('div.title-search-result').css('top', 83);
                $('.chooise-version__modal').css('top', 120);

                // hide popup mobile
                $('.phone-block__ico').removeClass('active');
                $('.popup-phone').removeClass('active');

                // hide popup city
                if(!window.popupCityOrder) {
                    window.popupCity.btnsShow.forEach(btn => btn.classList.remove('active'));
                    window.popupCity.close();
                }
            } else {
                $('.wrap.wrap-main').removeClass('header-smallest-nocatalog');
                $('.wrap.header-smallest')
                    .addClass('header-catalog')
                    .removeClass('header-smallest');
                $('div.title-search-result').css('top', 122);
                $('.chooise-version__modal').css('top', 160);
            }

            // if($('.basket__main').length) {
            //     checkSideBar();
            // }

            // $('#popup-city').dialog('close');
        });

        let popupLogin = $('.header')[0].querySelector('#popup-login');

        if(!popupLogin) return;

        let popupOffset = popupLogin.getBoundingClientRect().top + popupLogin.offsetHeight;

        if(popupOffset > window.innerHeight) {
            popupLogin.style.height = (window.innerHeight - popupLogin.getBoundingClientRect().top) + 'px';
            popupLogin.style.overflow = 'auto';
        }

        $(window).resize(function() {
            popupOffset = popupLogin.getBoundingClientRect().top + popupLogin.offsetHeight;

            popupLogin.style.height = popupOffset > window.innerHeight
                ? (window.innerHeight - popupLogin.getBoundingClientRect().top) + 'px'
                : 'auto';

            popupLogin.style.overflow = popupOffset > window.innerHeight 
                ? 'auto'
                : 'unset';
        })
    })();

    // btn up
    (() => {
        const btnUp = $('.btn-action-up');

        if (!btnUp.length) return;

        if ($(window).scrollTop() >= '250') {
            btnUp.fadeIn('slow');
        }

        $(window).scroll(function () {
            $(window).scrollTop() <= '480'
                ? btnUp.fadeOut('slow')
                : btnUp.fadeIn('slow');
        });

        btnUp.click(function () {
            $('html,body').animate({ scrollTop: 0 }, 'slow');
            return false;
        });
    })();

    // attach guide fancybox
    (() => {
        const fancyboxImages = document.querySelectorAll('#popup-guide a[data-fancybox]');
        if (fancyboxImages.length !== 0 && window.matchMedia("(min-width: 992px)").matches) {
            fancyboxImages.forEach(image => {
                // fancybox.open(image);
                $(image).fancybox();
            })
        }
    })();

    const arrParams = [];
    const productBox = document.querySelector('.product-box[data-elementid]');

    // isDetail
    if(productBox) {
        const galleryBlock = document.querySelector('.reviews-gallery');

        arrParams.push({
            data: {
                action: 'getGroupsInDetail',
                id: productBox.dataset.elementid
            },
            cb: productCharacters
        })
       
        if(galleryBlock) {
            arrParams.push({
                data: {
                    action: 'getPhotoVideo',
                    id: productBox.dataset.elementid
                },
                cb: productGallery
            })
        }
    }

    commonAjax(arrParams);
});
