import Popup from './popup_fn';

export default function initPoupCityCheckScript() {
    window.popupCityCheck = new Popup('.popup-check__city');

    if(!popupCityCheck.popup) return;
    

    const popupInners = document.querySelectorAll('.popup-check__city-inner');
    const headerLocationBlock = document.querySelector('.header .location-block');

    if(popupInners.length) {
        popupInners.forEach(inner => inner.addEventListener('click', eventClickInner))
    }

    if(headerLocationBlock) {
        headerLocationBlock.classList.add('custom-hover');
    }

    function eventClickInner(e) {
        const { target } = e;

        if(
            target instanceof HTMLElement &&
            target.classList.contains('popup-check__city-btn-change')
        ) {
            e.preventDefault();
            eventClickChange()
        }

        if(
            target instanceof HTMLElement &&
            target.classList.contains('popup-check__city-btn-submit')
        ) {
            e.preventDefault();
            eventClickSubmit(target)
        }
    }

    function eventClickChange() {
        if(!window.popupCity || !window.popupCity.popup) return;

        popupCityCheck.close();
        popupCityCheck.popup.parentElement.classList.remove('custom-hover');
        setTimeout(() => window.popupCity.open())
    }

    function eventClickSubmit(target) {
        let timeExpires = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

        window.setCookie('city', '1', { path: '/', expires: timeExpires });
        popupCityCheck.popup.parentElement.classList.remove('custom-hover');
        popupCityCheck.close();

        const alert = target.closest('.custom-alert__city');

        if(alert) {
            $(alert).slideDown(200, function() { this.remove() })
        }
    }
}