import { sender, setCatalogItemValues } from "../../common/functions";
import { initCompareSliders, recalcParamsHeight, reinitCompareItems, showHintCompare } from "./functions";

let debounce;

export function eventClickCompareBtn(target) {
    if(target.classList.contains('js-ajax-load')) return;

    target.classList.add('js-ajax-load');

    const data = new FormData();
    const action = target.classList.contains('active') ? 'RM_PRODUCT' : 'ADD_PRODUCT';

    data.append('action', action);
    data.append('id', target.dataset.id);

    const cb = function(res) {
        const responsive = JSON.parse(res);

        target.classList.remove('js-ajax-load');

        if(responsive.result) {
            const iconDetailtText = target.querySelector('.product__controls-text');

            switch(action) {
                case 'ADD_PRODUCT':
                    target.classList.add('active');
                    target.title = 'Убрать из сравнения';
                    if(iconDetailtText) iconDetailtText.textContent = 'В сравнении'
                    break;
                case 'RM_PRODUCT':
                    target.classList.remove('active');
                    target.title = 'Добавить в сравнение';
                    if(iconDetailtText) iconDetailtText.textContent = 'Сравнить'
                    break;
            }

            showHintCompare(responsive);
        }
    }

    sender(data, '/local/ajax/compare-ajax.php', cb);
}

export function eventClickTabCompare(e) {
    const {target} = e;

    clearTimeout(debounce);

    debounce = setTimeout(() => {
        const data = new FormData();
        const tabs = target.parentElement.querySelectorAll('.compare-tabs__item');

        tabs.forEach(tab => tab.classList.remove('active'));
        target.classList.add('active');

        data.append('action', 'GET_SECTION_HTML');
        data.append('section', target.dataset.sectionId);

        $('.spinner-div').fadeIn();

        const cb = function(res) {
            const container = document.querySelector('.compare-slider__container');

            if(!container) return;

            container.innerHTML = res;

            setCatalogItemValues(container).then(() => {
                const sliders = {
                    comapreSlider: container.querySelector('.compare-slider'),
                    comapreSliderTop: container.querySelector('.compare-slider__top')
                }

                const compareCheckbox = document.querySelector('[name="compare__inp-checkbox"]');
                const itemParams = document.querySelectorAll('.cat-params__list-item[data-uniform="N"]')

                if(itemParams.length) {
                    itemParams.forEach(param => {
                        $(param).css('display', compareCheckbox.checked ? 'none' : 'block');
                    });
                }

                recalcParamsHeight();
                initCompareSliders(sliders);
                reinitCompareItems();

                $('.spinner-div').fadeOut();
            });
        }

        sender(data, '/local/ajax/compare-ajax.php', cb);
    }, 200);
}

export function eventClickClearCompare(e) {
    e.preventDefault();

    const {target} = e;
    const alert = target.parentElement.querySelector('.alert-msg');

    if(!alert) return

    alert.classList.add('active');
}

export function eventClickCompareAlertBtn(e) {
    e.preventDefault();

    const {target} = e;

    target.closest('.alert-msg').classList.remove('active');

    if(target.dataset.btn !== 'send') return;

    $('.spinner-div').fadeIn();

    clearTimeout(debounce);

    debounce = setTimeout(() => {
        const data = new FormData();

        data.append('action', 'CLEAR');

        const cb = function(res) {
            const responsive = JSON.parse(res);
    
            if(responsive.result) {
                const title = document.querySelector('.compare-title');
                const contentInner = document.querySelector('.compare-content__inner');
    
                title.textContent = 'Сравнение товаров';
                contentInner.innerHTML = responsive.html;
            } else {
                alert('Ошибка! Попробуйте еще раз позднее.')
            }
        }

        sender(data, '/local/ajax/compare-ajax.php', cb);
    }, 200);
}

export function eventChangeCompareCheckbox(e) {
    const { target } = e;
    const itemParams = document.querySelectorAll('.cat-params__list-item[data-uniform="N"]');

    if(!itemParams.length) return;

    itemParams.forEach(param => {
        $(param).css('display', target.checked ? 'none' : 'block');
    });

    reinitCompareItems();
}

export function eventClickRemoveCompareItem(target, obj) {
    $('.spinner-div').fadeIn();

    clearTimeout(debounce);

    debounce = setTimeout(() => {
        const data = new FormData();
        const item = target.closest('.item-compare');
        const items = document.querySelectorAll(`[data-elementid="${item.dataset.elementid}"]`);
        const activeTab = document.querySelector('.compare-tabs__item.active');

        items.forEach(el => el.remove());

        obj.slider.update();
        obj.sliderTop.update();

        data.append('action', 'RM_PRODUCT');
        data.append('id', item.dataset.elementid);
        data.append('section_id', activeTab.dataset.sectionId);

        const cb = function(res) {
            const responsive = JSON.parse(res);

            if(responsive.result) {
                if(!parseInt(responsive.count_category)) {
                    location.reload();
                } else {
                    activeTab.textContent = `${activeTab.dataset.sectionName} (${responsive.count_category})`;
                    showHintCompare(responsive);
                }
            }

            $('.spinner-div').fadeOut();
        }

        sender(data, '/local/ajax/compare-ajax.php', cb);
    }, 200)
}