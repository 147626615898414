// import { setButtonsBuy, setFavoriteIcons } from "../functions";
import { setYmDataLayer } from "../catalog.item/functions";
import updateProductPrices from "./updateProductPrices";

export default function initMoreGoods() {

    var PageCont = $("#pagination-container");

    if(!PageCont.length) return;

    PageCont.on("click", "a.more_goods", function () {
        var link = PageCont.find("div.pagenav-box  a.btn-page-next").attr("href");
        var code = $(this).html();
        var elem = this;
        $(this).html("...");
        if (link !== undefined) {
            $.ajax({
                type: "POST", 
                url: link, data: {ajax_get_page: "y"}, 
                dataType: "html", 
                success: function (data) {
                    data = '<div class="wrap__ajax-response">' + data + '</div>';

                    var e = $(data).find(".pagination-items").find(".paginator-item");
                    var g = $(data).find(".pages-container").html();
                    const arrIds = [];

                    let container;
                    let itemTpl;

                    container = document.createElement('div');
                    container.classList.add('result-tpl');

                    itemTpl = document.createElement('div');
                    itemTpl.classList.add('pagination-items__grid-item');

                    $(container).append(itemTpl);

                    e.each(function(id, item) {
                        let itemPriceBlock = item.querySelector('.cat-price');

                        if(itemPriceBlock) {
                            itemPriceBlock.classList.add('bg-gray')
                        }

                        arrIds.push(item.dataset.elementid);
                        $(itemTpl).append(item);

                        if(++id % 4 == 0 && id !== e.length) {
                            itemTpl = document.createElement('div');
                            itemTpl.classList.add('pagination-items__grid-item');
                            $(container).append(itemTpl);
                        } 
                    });

                    container = $(container).find('.pagination-items__grid-item');

                    PageCont.find(".pagination-items .pagination-items__grid:last").append(container);
                    PageCont.find(".pages-container").html(g);
                    history.pushState("", "", link);
                    $(elem).html(code);

                    // block for Ym Ecom
                    (() => {
                        const items = PageCont[0].querySelectorAll('[data-elementid]');

                        if(!items.length) return;

                        const arrItems = [];
                        let title = false;

                        items.forEach((item, index) => {
                            const id = item.dataset.elementid;

                            if(!arrIds.includes(id)) return;

                            const obj = {}

                            obj.position = index + 1;
                            obj.el = item;

                            arrItems.push(obj);
                        });

                        if(link.includes('/new')) {
                            title = 'Раздел Новинки';
                        } else if(link.includes('/sale')) {
                            title = 'Раздел Рaспродажа';
                        } else if(link.includes('/hit')) {
                            title = 'Раздел Хиты';
                        }
    
                        setYmDataLayer(arrItems, 'impressions', title);
                    })();

                    

                    updateProductPrices(arrIds);
                }
            })
        }
        return false
    })
}