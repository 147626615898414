import { clickClear, clickClearPopup } from "./events";

// personal account functions
export default function paFunctions() {

    const buttonClear = document.querySelectorAll('#delete_favorite, #delete_expect');
    const alertBtns = document.querySelectorAll('.lk-counteragent__btns-group .alert-msg__btn');

    if(buttonClear.length) {
        buttonClear.forEach(btn => btn.addEventListener('click', clickClearPopup));
    }

    if(alertBtns.length) {
        alertBtns.forEach(btn => btn.addEventListener('click', clickClear))
    }
}