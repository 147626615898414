import { removeItemBlocks, updateBtnСart, updateItem } from "../catalog.item/functions";

// fn for update price after click "show more" + join some ajax
export default function updateProductPrices(
    arrIds,
    status = 'desk',
    container = false
) {
    if (!arrIds || !arrIds.length) return;

    const formData = new FormData();

    formData.append('action', 'get-item-prices');

    arrIds.forEach(function (element) {
        if (status === 'join_desk' || status === 'join_mobile') {
            element.id.forEach(function (id) {
                formData.append('id[]', id);
            });
        } else {
            formData.append('id[]', element);
        }
    });

    $.ajax({
        url: '/catalog/ajax.php',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        success: updateSuccess,
    });

    function updateSuccess(res) {
        const responsive = JSON.parse(res);
        const resObj = {};

        responsive.forEach(obj => resObj[obj.id] = obj);
        
        arrIds.forEach(element => {
            if (status === 'join_desk' || status === 'join_mobile') {
                element.id.forEach(updateEl)
            } else {
                updateEl(element)
            }
        });


        function updateEl(id) {
            id = String(id);

            let item;

            if(Object.keys(resObj).includes(id)) {
                const obj = resObj[id];

                if(container && $(container).find('#product_list').length) {
                    item = $(container).find(`[data-item-id="${obj.id}"]`);
                    item.each(function() { updateBtnСart(obj, this) });
                } else {
                    item = container 
                        ? $(container).find('[data-elementid="' + obj.id + '"]')
                        : $('[data-elementid="' + obj.id + '"]');

                    if (!item.length) {
                        item = $('#' + obj.id + '');
                    }

                    updateItem(item, obj);
                }

            } else {
                removeItemBlocks(id, container)
            }
        }

        if (container) {
            removeBgLayers(container);
        }

        if (document.querySelectorAll('.product').length !== 0) {
            $('.product').matchHeight();
        }
    }

    // fn for remove show elems for alsobuy and preview sliders
    function removeBgLayers(container) {
        if (!container) return;

        const loadElems = container.querySelectorAll('.bg-load');

        if (!loadElems.length) return;

        loadElems.forEach(function (el) {
            el.classList.remove('bg-load');
        });
    }
}
