import { eventAddToBasket, eventClickItemLink } from './events'

export default function initEventsCatalogItem() {

    document.addEventListener('click', (e) => {
        const { target } = e

        if (target instanceof HTMLElement && target.closest('.item-catalog')) {
            if (target.classList.contains('btn-add-cart')) {
                eventAddToBasket(e, target)
            }

            if (
                target.classList.contains('cat-photo') ||
                target.closest('.cat-name')
            ) {
                eventClickItemLink(e, target)
            }

            if(target.classList.contains('cat-complect')) {
                e.preventDefault();
                window.setCookie('required-slider', '1', { path:'/' })
                location.href = target.href;
            }
        }
    });
}