import { sender } from "../../common/functions";

let debounce;

export function clickClear(e) {
    e.preventDefault();

    const {target} = e;
    const buttonWrapper = target.closest('.lk-btn__wrap');
    if (buttonWrapper === null) return;
    const button = buttonWrapper.querySelector('.lk-btn');

    target.closest('.alert-msg').classList.remove('active');

    if(target.dataset.btn !== 'send') return;

    let link = '';

    if (button.id) {
        switch(button.id) {
            case 'delete_expect': link = '/local/ajax/clearSubscribe.php'
                break;
            case 'delete_favorite': link = '/local/ajax/favorite.php';
                break;
        }
    }

    if (link === '') {
        console.log('no link');
        return;
    };

    clearTimeout(debounce);

    debounce = setTimeout(() => {
        const data = new FormData();

        data.append('delete', '1');

        const cb = function(data) {
            location.reload();
        }

        sender(data, link, cb);
    }, 200);
}

export function clickClearPopup(e) {
    e.preventDefault();

    const {target} = e;
    const alert = target.parentElement.querySelector('.alert-msg');

    if(!alert) return

    alert.classList.add('active');
}